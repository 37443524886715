
:global {
    html, body {
        font-family: 'Roboto', sans-serif;
        scroll-behavior: smooth;
        background-color: #EBEBEB;
    }
}

.app {
    .titlePage {
        color: #000;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
            justify-content: flex-start;
        }

        .header {
            font-family: 'Playfair Display', serif;
            text-align: left;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media only screen and (max-width: 600px) {
                margin-top: 20px;
            }

            h1 {
                margin: 0;

                @media only screen and (max-width: 600px) {
                    font-size: 25px;
                }
            }

            a {
                color: #000;
            }

            .logo {
                width: 100px;
                border-radius: 10px;
                margin-bottom: 20px;

                @media only screen and (max-width: 600px) {
                    width: 70px;
                    margin-bottom: 10px;
                }
            }

            .downloadLinks {
                display: flex;
                flex-direction: row;
                margin-top: 10px;
                gap: 10px;
            }

            .download {
                height: 40px
            }
        }

        .imageContainer {
            display: flex;
            justify-content: space-evenly;
    
            img {
                height: 95vh;
                @media only screen and (max-width: 600px) {
                   height: 90vh;
                }
            }

        }
    }

    .addressPage {
        background-color: #4876e2;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .question {
            font-family: 'Playfair Display', serif;
            text-align: center;
        }
    }
}

.btn {
    text-decoration: none;
    color: #000;
    width: fit-content;
    padding: 10px;
    background: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background: rgb(238, 238, 238);
    }
}

.privacy {
    color: #6e6e6e;
    font-size: 13px;
}

.legal {
    font-size: 10px;
    padding: 10px;
    color: #919191;
    text-align: center;
}

footer {
    font-family: 'Playfair Display', serif;
    text-align: center;
}

.otherPageHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none !important;

    .text {
        font-family: 'Playfair Display', serif;
        font-size: 20px;
        font-weight: bold;
        color: #000;
    }
}

.mainContent {
    font-family: 'Playfair Display', serif;
    padding: 20px;
    min-height: 90vh;
}